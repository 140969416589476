<script>
  import signUpState from '@sign_up/mixins/sign_up_state'

  export default {
    mixins: [signUpState],

    computed: {
      familyAsMember () {
        return this.$signUpForm?.record.conflicting_family_membership?.as_member
      },

      familyAsOrganizer () {
        return this.$signUpForm?.record.conflicting_family_membership?.as_organizer
      },

      pageContent () {
        let content_key = this.familyAsOrganizer ? 'content_as_organizer_2' : 'content_as_member_2'

        const new_family_name = this.escapeHTML(this.$signUpSettings?.family?.name)
        const old_family_name = this.escapeHTML(this.familyAsOrganizer) || this.escapeHTML(this.familyAsMember)

        return this.$t(`family_selection.${content_key}`, {
          new_family_name: new_family_name,
          old_family_name: old_family_name
        })
      },

      acceptFamilyDeletionAsOrganizerLabel () {
        const old_family_name = this.escapeHTML(this.familyAsOrganizer)

        return this.$t('form.label.sign_up_form.accept_family_deletion_as_organizer', { old_family_name: old_family_name })
      },
    },

    methods: {
      acceptFamilyInvitation () {
        if(this.familyAsOrganizer && !this.$signUpForm.record.accept_family_deletion_as_organizer) {
          // show error message
          this.$set(this.$signUpForm.validation, 'accept_family_deletion_as_organizer', [this.$t('form.error.empty')])
          return
        }

        // save dessision for next sign up steps
        this.$set(this.$signUpForm.data, 'accept_family_replacement', true)

        this.$signUpForm.update()
      },

      rejectFamilyInvitation () {
        // let the family cookie expire to remove it
        document.cookie = 'family=; max-age=0; path=/;'
        // restart sign up without the family parameter
        window.history.replaceState({}, document.title, window.location.pathname)
        location.reload()
      }
    }
  }
</script>

<template>
  <div id='family_selection'>
    <div class='row'>
      <div class='column small-offset-1 small-22'>
        <div class='row'>
          <div class='column medium-centered medium-12 large-gutter-bottom input-section'>
            <p class='strong'>{{ $t('family_selection.step_title') }}</p>

            <p v-if='familyAsOrganizer' class='gutter-bottom' v-html="$t('family_selection.content_as_organizer_1')"></p>
            <p v-else class='gutter-bottom' v-html="$t('family_selection.content_as_member_1')"></p>

            <p class='large-gutter-bottom' v-html=pageContent></p>

            <mq-input-row v-if='familyAsOrganizer' class='large-gutter-bottom'>
              <mq-checkbox :form='$signUpForm' field='accept_family_deletion_as_organizer' :label='acceptFamilyDeletionAsOrganizerLabel'></mq-checkbox>
            </mq-input-row>

            <mq-input-row as='submit'>
              <div class='column text-center'>
                <mq-button @click="acceptFamilyInvitation" size='fluid' :color='primaryColor' class='small-gutter-bottom'>{{ $t('buttons.new_family_join') }}</mq-button>
                <br>
                <mq-button @click="rejectFamilyInvitation" size='fluid' :color='primaryColor' as='ghost' >{{ $t('buttons.new_family_reject') }}</mq-button>
              </div>
            </mq-input-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
