<script>
  import tinycolor from 'tinycolor2'

  export default {
    props: {
      info: {
        type: String,
        required: false,
        default: null
      }
    },
    
    computed: {
      contentColor () {
        const luminance = tinycolor(this.secondaryColor).getLuminance()
        return luminance > 0.65 ? '#000' : '#FFF'
      }
    }
  }
</script>


<template>
  <div class='friends_referral' :style='{backgroundColor: secondaryColor}'>
    <div class='friends_referral__container'>
      <div class='friends_referral__info' v-html='info' :style='{color: contentColor}'></div>
      <div class='friends_referral__icon'>
        <svg-icon name='friends_referral' :fill='contentColor' size='50px'></svg-icon>
      </div>
    </div>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .friends_referral {
    @include breakpoint(small only) {
      padding: 0 rem-calc(grid-column(7));
      margin: 0 rem-calc(grid-column(9)*-1);
      overflow: hidden;
    }

    &__container {
      color: $white;
      display: table-row;
    }

    &__icon {
      display: table-cell;
      vertical-align: middle;
      padding: 0 rem-calc(20);
      height: 100%;
  
      svg {
        width: 100% !important;
        height: auto !important;
      }
    }

    &__info {
      padding: rem-calc(10);
      display: table-cell;
      vertical-align: middle;
    }
  }
</style>