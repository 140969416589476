<script>
  export default {
    mounted () {
      this.$el.classList.add('fadeIn')
    },

    methods: {
      remove () {
        this.$el.classList.remove('fadeIn')
        this.$el.classList.add('fadeOut')
      }
    }
  }
</script>


<template>
  <div class="tooltip">
    <div class="wrapper">
      <div class="tooltip-inner">
        <slot></slot>
      </div>
      <div class="tooltip-arrow"></div>
    </div>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .tooltip {
    position: relative;
    margin-top: 5px;
    z-index: 10000;

    .tooltip-inner {
      background: #f9f9f9;
      color: black;
      padding: rem-calc(5) rem-calc(15);
      border-radius: 5px;
      box-shadow: 0px -5px 30px 10px rgba(0, 0, 0, 0.1);
    }

    .tooltip-arrow {
      position: absolute;
      top: -5px;
      right: rem-calc(50);
      width: 0;
      height: 0;
      border-style: solid;
      margin: 0 5px 0 5px;
      border-color: #f9f9f9;
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      z-index: 1;
    }
  }

  .fadeIn {
    animation-name: fadeIn;
    animation-delay: 2500ms;
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeOut {
    animation-name: fadeOut;
    animation-delay: 500ms;
    animation-duration: 500ms;
    animation-fill-mode: both;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

</style>
