export default {
  computed: {
    missingSubmitFields () {
      return this.$signUpForm && this.$signUpForm.record?.missing_fields.length ? this.$signUpForm.record?.missing_fields : null
    }
  },

  methods: {
    checkForMissingSubmitFields (fields) {
      if (!this.missingSubmitFields) return false
      return fields.some(missingField => this.missingSubmitFields.includes(missingField))
    }
  },
}