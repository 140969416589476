import DOMPurify from 'dompurify'

export default {
  methods: {
    infoboxKind () {
      if (this.$signUpSettings?.subscription_costs) {
        if (this.$signUpSettings?.signup_fee) { return 'costs_both' }
        return 'costs_subscription_only'
      } else {
        if (this.$signUpSettings?.signup_fee) { return 'costs_signup_only' }
        if (this.$signUpSettings?.feature_private_bookings) { return 'advantages_private' }
        if (this.$signUpSettings?.pool_provisionings_only) { return 'advantages_pool' }
        return 'advantages_commerical'
      }
    },

    listItems () {
      if (this.$signUpSettings?.custom_signup_advantages) {
        return this.$signUpSettings?.custom_signup_advantages.map(item => DOMPurify.sanitize(item))
      }

      let count = 0
      let content = []
      const infoboxKind = this.infoboxKind()
      const items = this.$t(`account_details.infobox.${infoboxKind}`)

      do {
        if (items.hasOwnProperty(count.toString())) {
          let item = this.$t(`account_details.infobox.${infoboxKind}.${count}`,
            {
              subscription_fee_amount: this.$signUpSettings?.subscription_costs?.formatted,
              subscription_recurrence: this.$t(`subscription_recurrence.adjective.${this.$signUpSettings?.subscription_recurrence}`),
              per_subscription_recurrence: this.$t(`subscription_recurrence.per.${this.$signUpSettings?.subscription_recurrence}`),
              signup_fee_amount: this.$signUpSettings?.signup_fee?.formatted
            }
          )
          content.push(item)
        }

        count ++
      } while (items.hasOwnProperty(count.toString()))

      return content
    },
  }
}
