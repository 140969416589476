<script>
  import tinycolor from 'tinycolor2'
  import signUpStepsRouting from "@sign_up/mixins/sign_up_steps_routing"

  export default {
    mixins: [signUpStepsRouting],

    props: {
      text: {
        type: String,
        required: false,
        default: null
      },
      current_step: {
        type: String
      },
      step_title: {
        type: String,
        required: false,
        default: null
      }
    },

    data () {
      return {
        stepTitle: this.step_title
      }
    },

    computed: {
      currentStep () {
        return Number(this.current_step)
      },

      allSteps () {
        return this.signUpSteps.filter((step) => {
          return (typeof step.href === 'undefined')
        })
      }
    },

    beforeMount () {
      this.createIndicatorStyle()
    },

    methods: {
      createIndicatorStyle () {
        const style = `
          .step-indicator .bullet.bullet--first-required {
            color: ${tinycolor(this.secondaryColor).setAlpha(.5).toString()};
          }
          .step-indicator .bullet.bullet--current,
          .step-indicator .bullet.bullet--finished {
            color: ${this.secondaryColor};
          }
        `
        this.createCustomStyle(style)
      },

      setStepTitle (step) {
        this.stepTitle = step?.title || null
      },

      classes (step, index) {
        return [
          'bullet',
          {'bullet--current': ((index + 1) === this.currentStep)},
          {'bullet--first-required': (step.name === this.nextRequiredStep?.name)},
          {'bullet--finished': !step.required},
        ]
      }
    }
  }
</script>


<template>
  <div class='step-indicator text-center' v-if="current_step && signUpStepsCount">
    <span v-for='(step, index) in allSteps' @mouseover='setStepTitle(step)' @mouseout='setStepTitle(null)' :class='classes(step, index)'>●</span>
    <div class='text hide-for-small-only'>
      <transition name="fade" mode="out-in">
        <div v-if='stepTitle' key='step_title'>{{ stepTitle }}</div>
        <div v-else key='step_counter'>{{ text }} {{ current_step }}/{{ signUpStepsCount }}</div>
      </transition>
    </div>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .step-indicator {
    color: $medium-gray;
    margin-bottom: rem-calc(40);


    .bullet {
      display: inline-block;
      margin: 0 rem-calc(15);
      vertical-align: middle;
      font-size: rem-calc(18);
      cursor: default;

      &--current {
        transform: scale(1.5);
      }
    }

    .text {
      margin-top: 0.5em;
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity 350ms;
    }
    .fade-enter, .fade-leave-to{
      opacity: 0;
    }
  }
</style>