<script>
  import tinycolor from 'tinycolor2'

  export default {
    props: {
      info: {
        type: String,
        required: false,
        default: null
      }
    },
    
    computed: {
      contentColor () {
        const luminance = tinycolor(this.secondaryColor).getLuminance()
        return luminance > 0.65 ? '#000' : '#FFF'
      }
    }
  }
</script>


<template>
  <div v-if='info' class='sign_up_campaign' :style='{backgroundColor: secondaryColor}'>
    <div class='sign_up_campaign__info' v-html='info' :style='{color: contentColor}'></div>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .sign_up_campaign {
    position: relative;
    width: rem-calc(130);
    height: rem-calc(130);
    border-radius: 50%;
    transform: rotate(10deg);

    &__info {
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem-calc(110);
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }
</style>