<script>
export default {

  props: {
    image: {
      type: Object
    }
  },

  computed: {
    carImageUrl () {
      return (this.image || this.$signUpSettings?.car_image)?.url
    }
  }
}
</script>

<template>
  <img :src='carImageUrl' class='car-image'/>
</template>

<style lang="scss">
.car-image {
  display: block;
  margin: 0 auto;
  margin-top: rem-calc(150);
}
</style>