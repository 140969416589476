import setupVue from '@/setup_moqo_vue'
import Vue from 'vue'
import App from '@sign_up/app'
import router from '@sign_up/vue-router'
import Resource from '@/resources/resources'
import { FIELDS } from "@sign_up/utils/constants"
import PortalVue from 'portal-vue'

// workaround for Babel 7 bringing in the Promise polyfill for IE11
if (false) { var workaroundPromise = new Promise(); }


// Plugins
Vue.use(PortalVue)

// require all available locale files
const translations = require.context('@sign_up/locales', false, /\.json$/)

// Register global components
Vue.component('step-wrapper', require("@sign_up/components/step_wrapper").default)
Vue.component('car-image', require("@sign_up/components/car_image").default)

Vue.mixin({
  computed: {
    SIGN_UP_FIELDS () {
      return FIELDS
    },
    $signUpForm () {
      return this.$root._signupForm
    },
    $signUpSettings () {
      return this.$root._signupForm?.requestData?.sign_up_settings
    },
    $currentUser () {
      return this.$root._signupForm?.requestData?.user
    }
  },
})

let vueConfig = {
  router,
  components: {
    App
  },
  locales: translations,
  fallbackLocale: 'en',
  created () {
    if (!this.store.props.team_token) return

    this._signupForm = Resource({
      name: 'sign_up_form',
      path: `/js_sign_up/${this.store.props.team_token}/account.json${window.location.search}`,
      wrapped: true,
      singularResource: true
    })
  },
  render: h => h(App)
}

setupVue(vueConfig)
