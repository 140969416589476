<script>
  import DOMPurify from 'dompurify'
  import ModalexternalCustomerIdInfo from '../components/modal_external_customer_id_info'
  import locales from '@moqomps/locales'

  export default {
    i18n: locales,

    data () {
      return {
        selectedTeamIndex: null
      }
    },

    created () {
      this.resetTeamSelection()
    },

    computed: {
      isLoading() {
        return this.$signUpForm.isLoading
      },

      signUpMandants() {
        return this.$signUpSettings?.sign_up_mandants || []
      },

      signUpMandantsPresent () {
        return !!this.signUpMandants.length
      },

      teamSelectionOptions () {
        return {
          standalone: true,
          label : this.$t('team_selection.select_carsharing'),
          error: this.$signUpForm.validation?.team_id
        }
      },

      selectedTeam () {
        if (this.signUpMandantsPresent && this.selectedTeamIndex !== null) {
          return this.$signUpSettings?.sign_up_mandants[this.selectedTeamIndex]
        }

        return this.$signUpSettings
      },

      pageTitle () {
        if (this.signUpMandantsPresent) return this.$t('team_selection.page_title.with_choice')
        return this.$t('team_selection.page_title.with_external_customer_id')
      },

      selectedTeamDescription () {
        if (this.selectedTeam?.sign_up_description) return this.selectedTeam.sign_up_description
        if (this.$signUpSettings?.sign_up_description) return this.$signUpSettings.sign_up_description
        return null
      },

      selectedTeamCarImage () {
        if (this.selectedTeam?.car_image) return this.selectedTeam.car_image
        return this.$signUpSettings?.car_image
      },

      externalCustomerIdSettings () {
        if (this.signUpMandantsPresent && this.selectedTeamIndex !== null) {
          return this.selectedTeam?.external_customer_id
        }

        if (!this.signUpMandantsPresent && this.$signUpSettings?.external_customer_id) {
          return this.$signUpSettings?.external_customer_id
        }

        return null
      },

      externalCustomerIdHint () {
        return this.$t('team_selection.external_customer_id_input_hint', {
          team: this.selectedTeam?.name,
          external_customer_id_name: this.externalCustomerIdSettings?.name
        })
      },

      externalCustomerIdInfo () {
        return this.$t("team_selection.external_customer_id_info", {
          team: this.selectedTeam?.name,
          external_customer_id_name: this.externalCustomerIdSettings?.name
        })
      },

      showExternalCustomerIdInfoButton () {
        return !!(this.externalCustomerIdSettings?.description)
      },

      contactEmailSubject () {
        return encodeURIComponent(`Kontakt-Aufnahme von der Team-Auswahl des Teams '${this.$signUpSettings?.name}'`)
      },

      contactURL () {
        return `/contact?team_id=${this.$layout.team_id}`
      },

      validatedFields () {
        if (this.signUpMandantsPresent && this.externalCustomerIdSettings) {
          return [...this.SIGN_UP_FIELDS.TEAM_SELECTION, ...this.SIGN_UP_FIELDS.EXTERNAL_CUSTOMER_ID]
        } else if (this.externalCustomerIdSettings) {
          return this.SIGN_UP_FIELDS.EXTERNAL_CUSTOMER_ID
        }
        return this.SIGN_UP_FIELDS.TEAM_SELECTION
      }
    },

    methods: {
      sanitizeHtml (content) {
        return DOMPurify.sanitize(content)
      },

      postForm () {
        this.$signUpForm.record.validate_options = this.validatedFields

        this.$signUpForm.update()
      },

      showExternalCustomerIdInfo () {
        this.openModal(ModalexternalCustomerIdInfo, {
          props: { customer_id_settings: this.externalCustomerIdSettings }
        })
      },

      resetTeamSelection () {
        this.$set(this.$signUpForm.record, 'team_id', null)
      },

      discountValue (team) {
        if (!team.sign_up_campaign_value) return null

        return this.$t("team_selection.discount_value", {
          value: team.sign_up_campaign_value.formatted
        })
      }
    },

    watch: {
      selectedTeam (team) {
        if (team?.team_id) {
          this.$set(this.$signUpForm.record, 'team_id', team.team_id)
          this.$set(this.$signUpForm.data, 'selected_team', team)
        }
      }
    }
  }
</script>


<template>
  <step-wrapper id='team_selection' :page_title='pageTitle'>
    <template slot='side'>
      <h2 v-html='pageTitle' class='hide-for-small-only gutter-bottom'></h2>

      <template v-if='selectedTeamDescription'>
        <p v-html='sanitizeHtml(selectedTeamDescription)'></p>
      </template>

      <template v-else-if='externalCustomerIdSettings'>
        <p v-html='externalCustomerIdInfo'></p>
      </template>

      <template v-else>
        <p>
          {{ $t('team_selection.generic_description') }}<br>
        </p>

        <p>
          {{ $t('team_selection.cant_find_team') }}<br>
          {{ $t('team_selection.contact_link') }}: <a :style='{color: primaryColor}' :href="contactURL">{{ $t('buttons.help_center_link') }} →</a>
        </p>
      </template>

      <car-image v-if="selectedTeamCarImage" :image="selectedTeamCarImage" class='hide-for-small-only'></car-image>
    </template>

    <template slot='main'>
      <form class='input-section' @submit.prevent='postForm'>
        <mq-input-row v-if='signUpMandantsPresent'>
          <mq-select v-model='selectedTeamIndex' v-bind='teamSelectionOptions'>
            <option v-for='(team, index) in signUpMandants' :value='index'>{{ team.name }} {{ discountValue(team) }}</option>
          </mq-select>
        </mq-input-row>

        <div v-if="externalCustomerIdSettings" class='external-customer-id' :class='{show: !!externalCustomerIdSettings}'>
          <mq-input-row class='no-gutter-bottom'>
            <mq-input
                :form='$signUpForm'
                field='external_customer_id'
                :with_info='showExternalCustomerIdInfoButton'
                @info-event='showExternalCustomerIdInfo'
                :label='externalCustomerIdSettings.name'>
            </mq-input>
          </mq-input-row>

          <p v-if='signUpMandantsPresent' class='text-center small grey'>{{ externalCustomerIdHint }}</p>
        </div>

        <mq-input-row as='submit'>
          <mq-form-errors :field_filter='validatedFields' />

          <div class='column text-center'>
            <mq-button size='small' :color='primaryColor' :is_loading='isLoading'>{{ $t('buttons.next') }}</mq-button>
          </div>
        </mq-input-row>
      </form>
    </template>
  </step-wrapper>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  #team_selection{
    @include breakpoint(small down) {
      padding-bottom: rem-calc(50);
    }

    form {
      margin-top: rem-calc(80);

      @include breakpoint(small down) {
        margin-top: 0;
      }
    }

    .external-customer-id {
      overflow: hidden;
      max-height: 0;
      transition: max-height 350ms;

      .mq-input {
        opacity: 0;
        transition: opacity 350ms;
      }

      &.show {
        max-height: 200px;

        .mq-input {
          opacity: 1;
        }
      }
    }
  }
</style>
