import missingFields from "@sign_up/mixins/missing_fields"

export default {
  mixins: [missingFields],

  computed: {
    signedIn () {
      return !!this.$currentUser
    },

    alreadyMember () {
      return this.$signUpForm.record?.joined_as?.driver ||
        this.$signUpForm.record?.joined_as?.passenger
    },

    conflictingFamilyMembership() {
      return !!(this.$signUpForm.record?.conflicting_family_membership)
    },

    hasOpenEnrollmentRequest () {
      if (!this.signedIn) return false

      const teamID = this.$signUpSettings?.team_id
      return this.$currentUser.enrollment_requests?.some((enrollmentRequest) => {
        return enrollmentRequest.team.id === teamID && enrollmentRequest.state === 'open'
      })
    },

    signUpMandantsAvailable () {
      // In case we have been redirected: do not force the user to select again which interrupts (payment) callbacks
      if (window.location.href.indexOf('redirect_status') >= 0) return false
      // Regular "start" parameter which is appended by signup to indicate the user already selected a concrete team
      if (window.location.href.indexOf('start=1') >= 0) return false

      return (
        !!this.$signUpSettings.sign_up_mandants?.length &&
        this.selectedTeamToken !== this.$signUpSettings.team_token
      )
    },

    selectedTeamToken () {
      return this.$signUpForm.data?.selected_team?.team_token
    },

    familyFriendsInvitation () {
      return this.$signUpSettings?.family
    },

    closedSelfRegistration () {
      return this.$signUpSettings?.feature_self_registration === 'closed'
    },

    externalCustomerIdRequired () {
      return this.$signUpSettings.external_customer_id && this.checkForMissingSubmitFields(['external_customer_id'])
    },

    teamSelectionRequired () {
      return (
        this.signUpMandantsAvailable ||
        this.externalCustomerIdRequired ||
        this.checkForMissingSubmitFields(['team_id'])
      )
    },

    familySelectionRequired () {
      if (this.$signUpForm.data?.accept_family_replacement) return false

      return this.familyFriendsInvitation && this.conflictingFamilyMembership
    },

    registerAsOrganizationMember () {
      if (this.$signUpSettings?.organization) return true
    },

    accountDetailsMissing () {
      return this.checkForMissingSubmitFields(this.SIGN_UP_FIELDS.ACCOUNT)
    },

    personalDetailsMissing () {
      if (this.$signUpSettings?.pool_provisionings_only) return false

      return this.checkForMissingSubmitFields(this.SIGN_UP_FIELDS.PERSONAL_DETAILS)
    },

    termsAcceptanceMissing () {
      return this.missingSubmitFields?.length === 1 &&
        this.checkForMissingSubmitFields(['terms_acceptance'])
    },

    paymentMethodMissing () {
      return this.$signUpForm.data?.skip_payment_method !== undefined ?
        !this.$signUpForm.data?.skip_payment_method :
        this.checkForMissingSubmitFields(this.SIGN_UP_FIELDS.PAYMENT_DETAILS)
    },

    teamCostsAvailable () {
      return !!(this.$signUpSettings?.signup_fee || this.$signUpSettings?.subscription_costs)
    },

    onlyTermsAcceptanceRequired () {
      if (this.signedIn && this.alreadyMember && this.registerAsOrganizationMember) return true
      return this.signedIn && !this.alreadyMember && this.termsAcceptanceMissing
    },

    signUpSubmitted () {
      return this.$signUpForm.record?.submitted
    },

    paymentAcceptanceRequired () {
      return this.teamCostsAvailable && !this.signUpSubmitted
    },

    paymentDetailsRequired () {
      if (this.$signUpSettings?.pool_provisionings_only) return false
      if (!this.$signUpSettings?.payment_method_handling_possible) return false

      if (this.familyFriendsInvitation) {
        // Family invitations might require a payment, but that will be done by the organizer
        return false
      }
      return this.paymentMethodMissing || this.paymentAcceptanceRequired
    },

    federatedLoginOptions () {
      return this.$signUpSettings?.federated_login_options
    },

    privacyPolicyUrl () {
      return `/privacy_policy?team_id=${ this.$signUpSettings?.team_id }`
    },

    eulaUrl () {
      return this.$signUpSettings?.provider_terms_url
    }
  }
}
