<template>
  <mq-modal-wrapper :title="$t('team_selection.modal.external_customer_id.title', {external_customer_id_name: customer_id_settings.name})">
    <div class='row'>
      <div class='column medium-15'>
        <p class='pre'>{{ customer_id_settings.description }}</p>
      </div>
      <div class='column medium-8 small-gutter-top'>
        <svg-icon name='document_search' size='100px'></svg-icon>
      </div>
    </div>

    <mq-input-row as='submit'>
      <div class='column text-center'>
        <mq-button size='small' :color='primaryColor' @click.native.prevent='cancel'>{{ $t('buttons.ok') }}</mq-button>
      </div>
    </mq-input-row>
  </mq-modal-wrapper>
</template>

<script>
  export default {
    props: {
      customer_id_settings: {
        type: Object,
        required: true
      }
    },
    methods: {
      cancel () {
        this.$emit('close')
      }
    }
  }
</script>