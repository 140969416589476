<script>
  import Vue from 'vue'
  import stripeVueLoader from '@/lib/stripe_vue_loader'
  import InfoBox from '@sign_up/components/info_box'
  import signUpStepsRouting from "@sign_up/mixins/sign_up_steps_routing"

  export default {
    mixins: [signUpStepsRouting],

    components: {
      InfoBox
    },
    data () {
      return {
        replacingMethodActive: false,
        inProgress: false,
        paymentAccepted: false,
        paymentAcceptanceError: null,
        eventBus: new Vue(),
        payPalActive: false,
        processingPaymentCallback: false
      }
    },

    created () {
      const params = new URLSearchParams(window.location.search);
      if (params.get('redirect_status') === 'succeeded' && params.get('setup_intent_client_secret')) {
        this.processPaymentMethodFromCallback(params.get('setup_intent_client_secret'));
      }

      this.eventBus.$on('payment-method-created', (type, response) => {
        this.processPaymentMethod(type, response)
      })

      this.eventBus.$on('failed-submit', () => {
        this.paymentAcceptanceError = [this.$t('payment_details.error.team_costs')]
      })

      this.$signUpForm.$on('validation', () => {
        this.inProgress = false
      })

      this.$signUpForm.$on('error', () => {
        this.inProgress = false
      })

      this.eventBus.$data.paypal_sign_up_notice = this.payPalNotice
    },

    computed: {
      paymentConfigured () {
        return !!this.$signUpForm.record?.payin_sources?.length
      },

      buttonsConfiguration () {
        let cancelLabel = this.$t('buttons.payment_form.cancel')
        let cancelClasses = 'column text-center'
        let submitLabel = this.$t('buttons.payment_form.save_and_next')
        let submitClasses = 'column text-center small-gutter-bottom '

        if (this.paymentConfigured) {
          cancelClasses = 'hide'
          submitLabel = this.$t('buttons.next')
        }

        if (this.replacingMethodActive && this.paymentConfigured) {
          cancelLabel = this.$t('buttons.cancel')
        }

        if (this.teamCostsAvailable) {
          cancelClasses = 'hide'
          submitLabel = this.$t('buttons.payment_form.pay_and_next')

          if (this.replacingMethodActive && this.paymentConfigured) {
            cancelLabel = this.$t('buttons.cancel')
          }
        }

        if (this.payPalActive) {
          submitClasses = 'hide'
        }

        return {
          submit: {
            classes: submitClasses,
            label: submitLabel
          },
          cancel: {
            classes: cancelClasses,
            label: cancelLabel,
          }
        }
      },

      allowSubmit () {
        return !this.teamCostsAvailable ? true : this.paymentAccepted
      },

      userHasToPayFor () {
        if (this.$signUpSettings.signup_fee && this.$signUpSettings.subscription_costs) return 'payment_costs_both'
        if (this.$signUpSettings.signup_fee ) return 'payment_costs_signup_only'
        if (this.$signUpSettings.subscription_costs) return 'payment_costs_subscription_only'
        return null
      },

      paymentDisclaimer () {
        return this.$t(`payment_details.team_costs.disclaimer.${this.userHasToPayFor}`, {
          signup_fee_amount: this.$signUpSettings.signup_fee?.formatted,
          subscription_fee_amount: this.$signUpSettings.subscription_costs?.formatted,
          subscription_recurrence: this.$t(`subscription_recurrence.adjective.${this.$signUpSettings?.subscription_recurrence}`),
          provider_terms_url: this.eulaUrl
        })
      },

      pageTitle () {
        if (this.teamCostsAvailable) return this.$t('payment_details.page_title.with_costs')
        return this.$t('payment_details.page_title.free')
      },

      pageContent () {
        if (this.teamCostsAvailable) return this.$t('payment_details.page_content.with_costs', {
          team: this.escapeHTML(this.$layout.header.title),
          submit_button_label: this.$t('buttons.payment_form.pay_and_next').toUpperCase()
        })
        return this.$t('payment_details.page_content.free')
      },

      loadingOverlayText () {
        if (this.$signUpForm.data?.skip_payment_method ) return this.$t('payment_details.loading_overlay_title.skip_payment_method')
        if (this.teamCostsAvailable) return this.$t('payment_details.loading_overlay_title.with_costs')
        return this.$t('payment_details.loading_overlay_title.free')
      },

      disallowSepaPayments () {
        return this.$signUpForm.record.disallow_sepa_payments || !this.$signUpSettings.allow_sepa_payments
      },

      payPalNotice () {
        let paypalInfo = this.$t('payment_details.paypal.info')
        if (this.teamCostsAvailable) paypalInfo = paypalInfo + ' ' + this.$t('payment_details.paypal.with_team_costs')
        return paypalInfo
      },

      options () {
        return {
          skip_payin_source_saving: true,
          payin_sources: { payin_sources: this.$signUpForm.record?.payin_sources || [] },
          first_name: this.$signUpForm.record.first_name,
          last_name: this.$signUpForm.record.last_name,
          email: this.$signUpForm.record.email,
          payment_disclaimer: this.$t('payment_details.disclaimer'),
          buttons_config: this.buttonsConfiguration,
          allow_submit: this.allowSubmit,
          event_bus: this.eventBus,
          disallow_sepa_payments: this.disallowSepaPayments,
          allow_paypal_payments: this.$signUpForm.record.allow_paypal_payments
        }
      }
    },

    methods: {
      replacingPaymentMethod (payload) {
        this.replacingMethodActive = payload.value
      },

      creatingPaymentMethod (stripeElementEvent) {
        if (this.processingPaymentCallback) { return }

        this.inProgress = stripeElementEvent.loading
      },

      tabChanged (tabName) {
        this.payPalActive = (tabName === 'PayPal')
      },

      cancel () {
        this.inProgress = true

        if (this.replacingMethodActive && this.paymentConfigured) {
          this.eventBus.$emit('cancel-replacing-method')
          return
        }

        // cancel button which is bound to this function is only available
        // if there is already a payment method or it can be skipped due to no team costs present
        this.postForm({skipPaymentMethod: true})
      },

      submit () {
        this.inProgress = true

        if (this.teamCostsAvailable && !this.paymentAccepted) {
          this.paymentAcceptanceError = [this.$t('payment_details.error.team_costs')]
          this.$signUpForm.$emit('validation', [this.paymentAcceptanceError])
          this.inProgress = false
          return
        }

        if (this.replacingMethodActive) {
          this.eventBus.$emit('submit')
          return
        }

        this.postForm()
      },

      processPaymentMethod (type, response) {
        if (!response) return false

        let token = response.id
        if (type === 'card') token = response.client_secret

        this.$signUpForm.record.payin_source_token = token
        this.postForm()
      },

      processPaymentMethodFromCallback (token) {
        this.processingPaymentCallback = true
        this.inProgress = true
        this.$signUpForm.record.payin_source_token = token
        this.postForm()
      },

      postForm (postParams=null) {
        if (postParams?.skipPaymentMethod) {
          // set as form param so the backend is able to skip the requirement for a payment method
          this.$signUpForm.record.skip_payment_method = postParams.skipPaymentMethod
          // save for step routing
          this.$set(this.$signUpForm.data, 'skip_payment_method', postParams.skipPaymentMethod)
        }

        this.$signUpForm.record.submit = true

        this.$signUpForm.update()
      },

      capitalize: function(s) {
        if(!s) return ''

        return (s+'').charAt(0).toUpperCase() + (s+'').slice(1);
      }
    }
  }
</script>


<template>
  <step-wrapper id='payment_details' :page_title='pageTitle' v-if="$signUpForm.record" current_step="3" >
    <template slot='side'>
      <h2 v-html='pageTitle' class='hide-for-small-only gutter-bottom'></h2>

      <info-box :title='pageTitle'>
        <p class='large-gutter-bottom' v-html='pageContent'></p>
      </info-box>

      <car-image class='hide-for-small-only'></car-image>
    </template>

    <template slot='main'>
      <mq-loading-overlay
          class='input-section'
          :show='inProgress'
          :text='loadingOverlayText'>

        <mq-payment-details
            v-bind='options'
            @payment-method-creating='creatingPaymentMethod'
            @payment-method-replacing='replacingPaymentMethod'
            @payment-method-created='processPaymentMethod'
            @tab-changed='tabChanged'
            @cancel="cancel">
        </mq-payment-details>

        <div v-if='teamCostsAvailable && !payPalActive' class='teamcost-paying-confirmation gutter-top gutter-bottom'>
          <mq-panel class='white text-center gutter-bottom'>

            <div v-if='$signUpSettings.signup_fee'>
              <span v-html="$t('payment_details.team_costs.signup_fee', {signup_fee_amount: $signUpSettings.signup_fee.formatted})"></span>
            </div>

            <div v-if='$signUpSettings.subscription_costs'>
              <span v-html="$t('payment_details.team_costs.subscription_fee', {subscription_fee_amount: $signUpSettings.subscription_costs.formatted, subscription_recurrence: capitalize($t(`subscription_recurrence.adjective.${$signUpSettings.subscription_recurrence}`)) } )"></span>
            </div>
          </mq-panel>

          <mq-input-row v-if='!payPalActive'>
            <mq-checkbox standalone field='payment_acceptance' :errors='paymentAcceptanceError' :label='paymentDisclaimer' v-model='paymentAccepted'></mq-checkbox>
          </mq-input-row>
        </div>

        <mq-input-row as='submit'>
          <mq-form-errors/>

          <div :class='buttonsConfiguration.submit.classes'>
            <mq-button size='small' :color='primaryColor' @click.native.prevent='submit' :is_loading='inProgress'>
              {{ buttonsConfiguration.submit.label }}
            </mq-button>
          </div>
          <div :class='buttonsConfiguration.cancel.classes'>
            <mq-button as='ghost' size='small' color='gray' @click.native.prevent='cancel' :is_disabled='inProgress'>
              {{ buttonsConfiguration.cancel.label }}
            </mq-button>
          </div>
        </mq-input-row>
      </mq-loading-overlay>
    </template>
  </step-wrapper>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  #payment_details{
    form {
      @include breakpoint(small down) {
        padding-bottom: rem-calc(50);
      }
    }

    .infobox {
      @include breakpoint(small only) {
        background-color: $nearly-white;
        padding-top: rem-calc(20) ;
        padding-bottom: rem-calc(20) ;
      }
    }
    .page-title {
      br {
        @include breakpoint(small only) {
          display: none;
        }
      }
    }

    sup.large {
      font-size: rem-calc(14);
    }
  }
</style>
