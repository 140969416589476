<script>
  import { currentLocale } from '@/current_user'
  import allCountries from "@/resources/locales/countries"
  import InfoBox from '@sign_up/components/info_box'
  import signUpState from "@sign_up/mixins/sign_up_state"

  export default {
    mixins: [signUpState],
    allCountries: allCountries( currentLocale() ),

    components: {
      InfoBox
    },

    computed: {
      prioritizedCountry () {
        return this.$layout.provider_country
      },

      showRegion () {
        const allowedCountries = ['IT'];  // Add other countries as needed
        return this.$signUpForm.record.additional_fields.includes('region') || allowedCountries.includes(this.$signUpForm.record.country)
      },

      additionalFields () {
        return this.$signUpForm.record.additional_fields.filter(field => field !== 'region')
      }
    },

    methods: {
      postForm () {
        this.$signUpForm.record.validate_options = this.SIGN_UP_FIELDS.PERSONAL_DETAILS

        if ( !this.paymentDetailsRequired ) {
          this.$signUpForm.record.skip_payment_method = true
          this.$signUpForm.record.submit = true
        }

        this.$signUpForm.update()
      }
    }
  }
</script>


<template>
  <step-wrapper id='personal_details' :page_title="$t('personal_details.page_title')" current_step="2" >
    <template slot='side'>
      <h2 v-html="$t('personal_details.page_title')" class='hide-for-small-only gutter-bottom'></h2>

      <info-box :title="$t('personal_details.page_title')">
        <p v-html="$t('personal_details.page_content')" class='gutter-bottom'></p>
      </info-box>

      <car-image class='hide-for-small-only'></car-image>
    </template>

    <template slot='main'>
      <form class='input-section customer-form' @submit.prevent='postForm'>
        <mq-input-row>
          <mq-input :form='$signUpForm' field='street' size="medium-16"></mq-input>
          <mq-input :form='$signUpForm' field='house_number' size="medium-8"></mq-input>
        </mq-input-row>

        <mq-input-row>
          <mq-input :form='$signUpForm' field='address_addition'></mq-input>
        </mq-input-row>

        <mq-input-row>
          <mq-input :form='$signUpForm' field='zipcode'  size='medium-8'></mq-input>
          <mq-input :form='$signUpForm' field='city'  size='medium-16'></mq-input>
        </mq-input-row>

        <mq-input-row>
          <mq-select :form='$signUpForm' field='country' size='medium-8'>
            <option :value='prioritizedCountry'>{{ $options.allCountries[prioritizedCountry] }}</option>
            <option disabled>---</option>
            <option v-for='(name, code) in $options.allCountries' :value='code'>{{ name }}</option>
          </mq-select>
          <mq-autocomplete v-if="showRegion" :country='this.$signUpForm.record.country' :form='$signUpForm' field='region' size='medium-16'></mq-autocomplete>
        </mq-input-row>

        <mq-input-row>
          <mq-input-date :form='$signUpForm' field='birthday'></mq-input-date>
        </mq-input-row>

        <mq-input-row>
          <mq-input-mobile-nr :form='$signUpForm' field='mobile_number' :preferredCountries='[prioritizedCountry]'></mq-input-mobile-nr>
        </mq-input-row>

        <mq-input-row v-for='additionalField in additionalFields' :key='additionalField'>
          <mq-input :form='$signUpForm' :field='additionalField'></mq-input>
        </mq-input-row>

        <mq-input-row as='submit'>
          <mq-form-errors :field_filter='SIGN_UP_FIELDS.PERSONAL_DETAILS' />

          <div class='column text-center'>
            <mq-button size='small' :color='primaryColor' :is_loading='$signUpForm.isLoading'>
              {{ $t('buttons.next') }}
            </mq-button>
          </div>
        </mq-input-row>
      </form>
    </template>
  </step-wrapper>
</template>

<style lang='scss'>
  @import '~@moqomps/style';

  #personal_details{
    form {
      @include breakpoint(small down) {
        padding-bottom: rem-calc(50);
      }
    }

    .infobox {
      @include breakpoint(small only) {
        background-color: $nearly-white;
        padding-top: rem-calc(20) ;
        padding-bottom: rem-calc(20) ;
      }
    }
    .page-title {
      br {
        @include breakpoint(small only) {
          display: none;
        }
      }
    }
  }
</style>
