<script>
  import MobileApps from '@moqomps/components/partials/mobileApps'
  import QrCodeSvg from '@moqomps/components/partials/qrCodeSvg'
  import signUpStepsRouting from "@sign_up/mixins/sign_up_steps_routing"

  export default {
    mixins: [signUpStepsRouting],

    components: {
      MobileApps,
      QrCodeSvg
    },

    computed: {
      appStoreURL () {
        return this.$signUpSettings.mobile_app_url
      },

      profileUrl() {
        return `/d/${ this.$signUpSettings.team_id }/js_user_profile`
      },

      magicLoginUrl () {
        return this.$currentUser?.magic_login_url
      },

      pageTitle () {
        if (this.closedSelfRegistration && this.hasOpenEnrollmentRequest) {
          return this.$t('app_details.page_title_closed_registration', {app: this.appName})
        }

        return this.$t('app_details.page_title', {app: this.appName})
      },

      pageContent () {
        if (this.closedSelfRegistration && this.hasOpenEnrollmentRequest) {
          return this.$t('app_details.page_content_closed_registration', {app: this.appName})
        }

        return this.$t('app_details.page_content', {app: this.appName})
      }
    }
  }
</script>



<template>
  <step-wrapper id='app_details'>
    <template slot='side'>
      <div class='hide-for-small-only'>
        <h2 v-html='pageTitle' class='page-title gutter-bottom'></h2>

        <p class='large-gutter-bottom' v-html='pageContent'></p>

        <car-image></car-image>
      </div>
    </template>

    <template slot='main'>
      <div class='main'>
        <h3 v-html="$t('app_details.page_title', {app: appName})" class='page-title show-for-small-only'></h3>

        <div class='row app-section'>
          <div class='column medium-16 medium-centered gutter-bottom'>
            <h3 v-html='appName' class='bold page-title'></h3>
            <mobile-apps orientation='vertical'>
              <template slot='text'>
                <h3 v-html="$t('app_details.apps_title')" class='small-gutter-top gutter-bottom hide-for-small-only'></h3>
              </template>
            </mobile-apps>
          </div>

          <div class='qr-code column medium-16 medium-centered hide-for-small-only'>
            <p class='qr-code__description'>{{ $t('app_details.qr_code_description')}}</p>
            <p class='qr-code__image'>
              <qr-code-svg :content='appStoreURL'></qr-code-svg>
            </p>
          </div>
        </div>

        <div class='row column buttons-section hide-for-small-only'>
          <p class='text-center'>
            <mq-button :color='primaryColor' size='small' :href='profileUrl'>{{ $t('buttons.to_profile') }}</mq-button>
          </p>
        </div>

        <mq-input-row as='submit' class='show-for-small-only'>
          <p class='text-center'>
            <mq-button as='link' size='small' :color='primaryColor' :href='magicLoginUrl'>{{ $t('buttons.continue_in_app') }}</mq-button>
          </p>
        </mq-input-row>
      </div>
    </template>
  </step-wrapper>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  #app_details{
    form {
      @include breakpoint(small down) {
        padding-bottom: rem-calc(50);
      }
    }

    .smartphone-image {
      max-width: rem-calc(75);
    }

    .main {
      @include breakpoint(small only) {
        padding-top: rem-calc(20) ;
        padding-bottom: rem-calc(20) ;
      }
    }

    .app-section {
      text-align: center;
      margin-bottom: rem-calc(50);
    }

    .app-icon {
      text-align: center;

      img {
        max-width: rem-calc(100);
      }
    }

    .qr-code{
      &__description {
        text-align: left;
      }

      &__image {
        display: inline-block;
        width: rem-calc(200);
        padding: rem-calc(10);
        background-color: $white;
      }
    }

    .buttons-section {
      margin-bottom: rem-calc(100);
      padding-top: rem-calc(50);
      border-top: 2px solid $medium-gray;

      @include breakpoint(small only) {
        margin-bottom: rem-calc(50);
      }
    }

    .page-title {
      @include breakpoint(small only) {
        margin-bottom: rem-calc(50);
      }

      br {
        @include breakpoint(small only) {
          display: none;
        }
      }
    }
  }
</style>
