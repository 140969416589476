import Vue from 'vue'
import VueRouter from 'vue-router'
import { currentEntityId } from '@/current_user'

import StepSubscriptionSelection from '@sign_up/pages/step_subscription_selection'
import StepTeamSelection from '@sign_up/pages/step_team_selection'
import StepFamilySelection from '@sign_up/pages/step_family_selection'
import StepAccountDetails from '@sign_up/pages/step_account_details'
import StepPersonalDetails from '@sign_up/pages/step_personal_details'
import StepPaymentDetails from '@sign_up/pages/step_payment_details'
import StepSuccess from '@sign_up/pages/step_success'
import AppDetails from '@sign_up/pages/step_app_details'
import TermsConditions from '@sign_up/pages/step_terms_conditions'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/subscription-selection', name: 'subscription-selection', component: StepSubscriptionSelection},
    { path: '/team-selection', name: 'team-selection', component: StepTeamSelection},
    { path: '/family-selection', name: 'family-selection', component: StepFamilySelection},
    { path: '/account', name: 'account', component: StepAccountDetails},
    { path: '/personal', name: 'personal', component: StepPersonalDetails},
    { path: '/payment', name: 'payment', component: StepPaymentDetails},
    { path: '/success', name: 'success', component: StepSuccess},
    { path: '/app', name: 'app', component: AppDetails},

    { path: '/terms-conditions', name: 'terms-conditions', component: TermsConditions},
  ]
})


export default router
