<script>
  export default {
    props: {
      title: {
        type: String,
        required: false,
        default: null
      }
    },
    
    mounted() {
      let submitRow = document.querySelector('.in-app .row--submit')

      if (submitRow) {
        this.$el.style.marginBottom = `${submitRow.clientHeight - 100 }px`
      }
    }
  }
</script>


<template>
  <div class="info-box">
    <p v-if='title' class='bold show-for-small-only'>
      <svg-icon name='info' size='40px' :fill='secondaryColor' class='info-box__title_icon'></svg-icon>
      <span v-html='title' class='info-box__title'></span>
    </p>
    <slot></slot>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  .info-box {
    @include breakpoint(small only) {
      background-color: $nearly-white;
      padding: rem-calc(20) rem-calc(grid-column(9));
      margin: 0 rem-calc(grid-column(9)*-1);

      &__title_icon {
        margin-left: rem-calc(-7);
      }

      &__title {
        br {
          display: none;
        }
      }
    }
  }
</style>