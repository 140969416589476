<script>
  import signUpStepsRouting from "@sign_up/mixins/sign_up_steps_routing"
  import errorMessagesPresenter from "@sign_up/mixins/error_messages_presenter"

  export default {
    mixins: [signUpStepsRouting, errorMessagesPresenter],

    provide () {
      const mqFormErrorsOptions = {}

      Object.defineProperties(mqFormErrorsOptions, {
        '_field_labels': {
          enumerable: true,
          get: () => this.$t('form.label.sign_up_form'),
        },
        '_form': {
          enumerable: true,
          get: () => this.$signUpForm,
        }
      })

      return {...mqFormErrorsOptions}
    },

    data () {
      return {
        isLoading: true
      }
    },

    computed: {
      showLoginButton () {
        return !this.$currentUser
      }
    },

    created () {
      if (this.store.props.team_group?.subscription_mandants) {
        this.routeToStep({name:'subscription-selection'})
        this.isLoading = false
        return
      }

      this.$signUpForm.$once('get', () => {
        document.title = this.$signUpSettings.name
        // Used for in-App-Sign-Up to show the fitting success message at the end of registration
        if (!this.$currentUser) this.$set(this.$signUpForm.data, 'new_registration', true)

        // If it's not possible to handle the payment method in sign up at all
        // allow backend to skip the validation regarding 'payin_source_token' no mather what
        if (!this.paymentDetailsRequired) {
          this.$signUpForm.record.skip_payment_method = true
        }

        this.routeToNextRequiredStep()
        this.isLoading = false
      })

      this.$signUpForm.$on('update', () => {
        this.routeToNextRequiredStep()
      })
    }
  }
</script>


<template>
  <mq-layout :is_loading='isLoading' id='sign_up' disable_navigation disable_magic_login :show_login_button='showLoginButton'>
    <router-view></router-view>
  </mq-layout>
</template>

<style lang='scss'>
  @import '~@moqomps/style';

  #main {
    min-height: auto;

    @include breakpoint(medium down) {
      min-height: 100vh;
    }
  }

  .in-app .row--submit {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    padding-bottom: 1.25rem;
    box-shadow: 0 rem-calc(15) rem-calc(30) 0 $dark-gray;
    z-index: 2;
  }

  .browser-warning {
    background-color: #ff8080;
    padding: 10px;
    position: fixed;
    z-index: 10000; // navigation already has 9999 ...
  }
</style>
