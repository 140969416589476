<script>
  import RequiredAcceptanceCheckboxes from '@sign_up/components/required_acceptance_checkboxes'
  import signUpState from '@sign_up/mixins/sign_up_state'
  import axios from 'axios'

  export default {
    mixins: [signUpState],

    components: {
      RequiredAcceptanceCheckboxes
    },

    created () {
      if (this.$signUpForm?.record && !this.$currentUser) {
        this.$router.push('account').catch(err => {})
      }
    },

    computed: {
      pageContent () {
        return this.$t('already_login.page_content_2', {
          user_email: this.$currentUser?.email,
          team: this.escapeHTML(this.$layout.header.title)
        })
      },
    },

    methods: {
      submitJoinRequest () {
        this.$signUpForm.record.validate_options = this.SIGN_UP_FIELDS.TERMS_CONDITIONS
        this.$refs.acceptance.validateRequiredCheckboxes()

        if (this.$refs.acceptance.requiredCheckboxesAccepted) {
          if (!this.paymentAcceptanceRequired) this.$signUpForm.record.submit = true
          this.$signUpForm.update()
        }

      },
      logoutUser () {
        axios.get('/logout').then(response => {
          if (response.status) {
            location.reload(true)
          }
        })
      }
    }
  }
</script>

<template>
  <div id='already_login'>
    <div class='row'>
      <div class='column small-offset-1 small-22'>
        <div class='row'>
          <div class='column medium-centered medium-12 large-gutter-bottom input-section'>
            <p class='strong'>{{ $t('already_login.page_content_1') }}</p>
            <p class='large-gutter-bottom' v-html=pageContent></p>
            <p v-if="this.registerAsOrganizationMember">{{ $t('already_login.page_content_organization') }}</p>

            <required-acceptance-checkboxes ref="acceptance" />

            <mq-input-row as='submit'>
              <mq-form-errors :field_filter='SIGN_UP_FIELDS.TERMS_CONDITIONS' />

              <div class='column text-center'>
                <mq-button @click="submitJoinRequest" size='small' :color='primaryColor' class='small-gutter-bottom'>{{ $t('buttons.next') }}</mq-button>
                <mq-button v-if="!$layout.in_app" @click="logoutUser" as='ghost' size='fluid' :color='primaryColor'>{{ $t('buttons.create_new_account') }}</mq-button>
              </div>
            </mq-input-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
