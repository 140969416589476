<script>
  import minimumAgeRequirement from "@sign_up/mixins/minimum_age_requirement"
  import eulaRequirement from "@sign_up/mixins/eula_requirement"
  import termsRequirement from "@sign_up/mixins/terms_requirement"
  import signUpState from "@sign_up/mixins/sign_up_state"

  export default {
    mixins: [
      minimumAgeRequirement,
      eulaRequirement,
      termsRequirement,
      signUpState
    ],
    
    computed: {
      termsAcceptanceLabel () {
        return this.$t('form.label.sign_up_form.terms_acceptance', {privacy_policy_url: this.privacyPolicyUrl})
      },

      eulaAcceptanceLabel () {
        return this.$t('form.label.sign_up_form.eula_acceptance', {eula_url: this.eulaUrl})
      },

      minimumAgeRequirementLabel () {
        return this.$t('form.label.sign_up_form.minimum_age_requirement_acceptance', {age: this.minimumAgeRequirement})
      },

      requiredCheckboxesAccepted () {
        return this.minimumAgeRequirementAccepted &&
          this.eulaRequirementAccepted &&
          this.termsRequirementAccepted
      }
    },
    
    methods: {
      validateRequiredCheckboxes () {
        this.validateMinimumAgeRequirement()
        this.validateEulaRequirement()
        this.validateTermsRequirement()
      },
    }
  }
</script>

<template>
  <div>
    <mq-input-row>
      <mq-checkbox :form='$signUpForm' field='terms_acceptance'>
        <i18n path='form.label.sign_up_form.terms_acceptance' tag='span'>
          <template #privacy_policy_link>
            <a target='_blank' rel='nofollow noreferrer noopener' :href='privacyPolicyUrl'>{{ $t('buttons.privacy_policy') }}</a>
          </template>
        </i18n>
      </mq-checkbox>
    </mq-input-row>
  
    <mq-input-row v-if='eulaRequirement'>
      <mq-checkbox :form='$signUpForm' field='eula_acceptance' :label='eulaAcceptanceLabel'>
        <i18n path='form.label.sign_up_form.eula_acceptance' tag='span'>
          <template #eula_link>
            <a target='_blank' rel='nofollow noreferrer noopener' :href='eulaUrl'>{{ $t('buttons.eula') }}</a>
          </template>
        </i18n>
      </mq-checkbox>
    </mq-input-row>
  
    <mq-input-row v-if='minimumAgeRequirement'>
      <mq-checkbox :form='$signUpForm' field='minimum_age_requirement_acceptance' :label='minimumAgeRequirementLabel'></mq-checkbox>
    </mq-input-row>
  </div>
</template>