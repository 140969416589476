<template>
  <mq-modal-wrapper :title="$t('account_details.modal.license_info.title')">
    <div class='row'>
      <div class='column medium-12'>
        <p>
          <strong>{{ $t('account_details.modal.license_info.eu_license_title') }}</strong><br>
          <span v-html="$t('account_details.modal.license_info.eu_license_content')"></span>
        </p>
        <p>
          {{ $t('account_details.modal.license_info.old_license_content') }}
        </p>
      </div>
      <div class='column medium-12'>
        <img src='~@sign_up/assets/images/driver-license-front.jpg' alt='Drivers License Front'/>
      </div>
    </div>
  </mq-modal-wrapper>
</template>

<script>
  export default {
    methods: {
      cancel () {
        this.$emit('close')
      }
    }
  }
</script>