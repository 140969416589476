export default {
  created () {
    this.$set(this.$signUpForm.record, 'terms_acceptance', !!(this.$signUpForm.record.terms_acceptance))
  },

  computed: {
    termsRequirementAccepted () {
      return this.$signUpForm.record.terms_acceptance
    }
  },

  methods: {
    validateTermsRequirement () {
      if(!this.$signUpForm.record.terms_acceptance) {
        this.$set(this.$signUpForm.validation, 'terms_acceptance', [this.$t('form.error.empty')])
      } else {
        this.$delete(this.$signUpForm.validation, 'terms_acceptance')
      }
    }
  }
}
