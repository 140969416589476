<script>
  import StepIndicator from '@sign_up/components/step_indicator'
  import signUpStepsRouting from "@sign_up/mixins/sign_up_steps_routing"

  export default {
    mixins: [signUpStepsRouting],

    components: {
      StepIndicator
    },

    props: {
      current_step: {
        type: String
      },

      page_title: {
        type: String,
        required: false,
        default: null
      },

      step_title: {
        type: String,
        required: false,
        default: null
      },

      hide_step_indicator: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      mainSectionClasses () {
        return ['column',
          'main-section',
          'medium-12',
          'medium-offset-1',
          'medium-push-11',
          {'large-gutter-top-for-medium': this.current_step},
          {'apply-additional-padding': !this.signUpStepsCount},
          'large-gutter-bottom']
      }
    }
  }
</script>


<template>
    <div class='row'>
      <div class='column small-offset-1 small-22'>
        <div class='row'>
          <div :class='mainSectionClasses'>
            <step-indicator
                :text="$t('form.step_indicator')"
                :current_step="current_step"
                :step_title='step_title'
                v-show="!hide_step_indicator"
            ></step-indicator>

            <h2 v-if='page_title' v-html='page_title' class='large-gutter-bottom show-for-small-only page-title'></h2>

            <slot name='main'></slot>
          </div>
          <div class="column medium-11 medium-pull-13">
            <slot name='side'></slot>
          </div>
        </div>
      </div>
    </div>
</template>

<style lang='scss'>

  .apply-additional-padding {
    padding-top: 6rem;
    margin-top: 1rem
  }
</style>˝