export default {
  created () {
    this.$set(this.$signUpForm.record, 'eula_acceptance', !(this.$signUpSettings?.feature_require_eula_acceptance_upon_signup))
  },

  computed: {
    eulaRequirement () {
      return this.$signUpSettings?.feature_require_eula_acceptance_upon_signup
    },

    eulaRequirementAccepted () {
      return !this.eulaRequirement || this.$signUpForm.record.eula_acceptance
    }
  },

  methods: {
    validateEulaRequirement () {
      if(this.eulaRequirement && !this.$signUpForm.record.eula_acceptance) {
        this.$set(this.$signUpForm.validation, 'eula_acceptance', [this.$t('form.error.empty')])
      } else {
        this.$delete(this.$signUpForm.validation, 'eula_acceptance')
      }
    },
  }
}
