import signUpState from "@sign_up/mixins/sign_up_state"

export default {
  mixins: [signUpState],

  data () {
    return {
      stepToStepRouting: false,
      firstEntryPoint: null
    }
  },

  computed: {
    signUpStepsCount () {
      if (!this.personalDetailsMissing) {
        return null
      }

      if (this.paymentDetailsRequired) {
        return 3
      }

      return 2
    },

    signUpSteps () {
      let steps = []

      if (this.familySelectionRequired) {
        steps.push({
          name: 'family-selection',
          title: '',
          required: true
        })
      }

      if (this.teamSelectionRequired) {
        steps.push({
          name: 'team-selection',
          title: '',
          required: true
        })
      }

      if (this.onlyTermsAcceptanceRequired) {
        steps.push({
          name: 'terms-conditions',
          title: '',
          required: this.termsAcceptanceMissing || this.registerAsOrganizationMember
        })
      }

      steps.push({
        name: 'account',
        title: this.$t('account_details.step_title'),
        required: this.accountDetailsMissing
      })

      if (this.personalDetailsMissing) {
        steps.push({
          name: 'personal',
          title: this.$t('personal_details.step_title'),
          required: this.personalDetailsMissing
        })
      }

      if (this.paymentDetailsRequired) {
        steps.push({
          name: 'payment',
          title: this.$t('payment_details.step_title'),
          required: true
        })
      }

      return steps
    },

    nextRequiredStep () {
      return this.signUpSteps.find(x => x.required === true)
    },

    nextPossibleStep() {
      return this.signUpSteps[0]
    },

    skipSignUp () {
      return this.signedIn &&
        this.alreadyMember &&
        !this.missingSubmitFields &&
        !this.stepToStepRouting
    }
  },

  methods: {
    routeToStep (step) {
      if (step.href) {
        this.redirectToLocation(step.href)
        return
      }
      if (step.name && step.name == this.firstEntryPoint) {
        this.$router.replace(step.name).catch(err => {})
      } else if (step.name) {
        this.$router.push(step.name).catch(err => {})
      }
      this.stepToStepRouting = true
    },

    redirectToSelectedTeamSignUp () {
      this.redirectToLocation(`/js_sign_up/${this.selectedTeamToken}?start=1`)
    },

    redirectToAppOrMagicLogin () {
      if (this.$layout.in_app && this.$currentUser?.magic_login_url) {
        this.routeToStep({name:'success'})
      } else {
        this.routeToStep({name:'app'})
      }
    },

    redirectToLocation (url) {
      window.location.href = url
    },

    routeToNextRequiredStep () {
      if (this.skipSignUp || this.signUpSubmitted) {
        this.redirectToAppOrMagicLogin()
        return
      }

      if (this.selectedTeamToken) {
        this.redirectToSelectedTeamSignUp()
        return
      }

      var nextStep = this.nextRequiredStep || this.nextPossibleStep
      this.firstEntryPoint = this.firstEntryPoint || nextStep.name

      this.routeToStep(nextStep)
    }
  },

  watch:{
    $route (to, from) {
      // lock the user in the sign up step 'app' after successfully submitting all steps
      if (this.signUpSubmitted) {
        this.redirectToAppOrMagicLogin()
        return
      }
      // Block user from navigating to previous step by using browsers native navigation
      if (this.nextRequiredStep && this.nextRequiredStep.name !== to.name) this.routeToStep(this.nextRequiredStep)
      // force redirection as long as user don't provide a valid external customer id
      if (this.externalCustomerIdRequired) this.routeToStep({name:'team-selection'})
    }
  }
}
