export default {
  created () {
    // mobile only
    if (window.innerWidth > 768) return

    this.$signUpForm.$on('validation', this.scrollAndShowErrorMessages)
  },

  methods: {
    scrollAndShowErrorMessages () {
      this.$nextTick(()=>{
        const mainSectionHeight = document.querySelector(".main-section")?.scrollHeight
        const inputSectionTop = document.querySelector(".input-section")?.offsetTop
        const submitRowHeight = document.querySelector(".row--submit")?.scrollHeight
        const windowHeight = window.innerHeight

        // scroll to bottom of main section
        const scrollPosition = mainSectionHeight - windowHeight + submitRowHeight
        window.scrollTo({top: scrollPosition, left: 0, behavior: 'smooth'})
        // Wait 1.2 sec and scroll back to the top, there could be errors shown too
        setTimeout(() => {  window.scrollTo({top: inputSectionTop, left: 0, behavior: 'smooth'}) }, 1200);
      })
    }
  }
}
