<script>
  import DOMPurify from 'dompurify'

  export default {
    computed: {
      teamGroup () {
        return this.store.props.team_group
      },

      teamGroupBenefits () {
        if (!this.teamGroup.list_of_benefits) return

        return this.teamGroup.list_of_benefits.map(item => this.sanitizeHtml(item))
      },

      subscriptionMandants () {
        return this.teamGroup.subscription_mandants || []
      },

      subscriptionMandantsPresent () {
        return !!this.subscriptionMandants.length
      },
      
      carImage () {
        return this.teamGroup.car_image
      }
    },

    methods: {
      sanitizeHtml (content) {
        return DOMPurify.sanitize(content)
      },

      listItems (team) {
        let content = []

        if (team.subscription_costs) {
          const subscription_recurrence = this.$t(`subscription_recurrence.per.${team.subscription_recurrence}`)
          content.push(this.$t('subscription_selection.benefits.subscription_fee', {subscription_fee_amount: team.subscription_costs.formatted, subscription_recurrence: subscription_recurrence}))
        } else {
          content.push(this.$t('subscription_selection.benefits.no_subscription_fee'))
        }

        if (team.signup_fee) {
          content.push(this.$t('subscription_selection.benefits.signup_fee', {signup_fee_amount: team.signup_fee.formatted}))
        } else {
          content.push(this.$t('subscription_selection.benefits.no_signup_fee'))
        }

        return content
      },

      planDescription (team) {
        return this.sanitizeHtml(team.plan_description || team.custom_signup_restrictions)
      },

      signUpUrl (team) {
        return `/js_sign_up/${team.team_token}`
      }
    }
  }
</script>


<template>
  <step-wrapper id='subscription_selection' :page_title='$t("subscription_selection.page_title")'>
    <template slot='side'>
      <h2 v-html='$t("subscription_selection.page_title")' class='hide-for-small-only gutter-bottom'></h2>
      <mq-list v-if='teamGroupBenefits' :items='teamGroupBenefits' :checkmark='true' :color='secondaryColor'></mq-list>
      <car-image v-if="carImage" :image="carImage" class='hide-for-small-only'></car-image>
    </template>

    <template slot='main'>
      <mq-extended-panel v-for='team in subscriptionMandants' :key='team.id' :color='primaryColor' class='input-section gutter-bottom'>
        <template slot='top'>
          <h3 class='small-gutter-bottom'>{{ team.plan_name || team.name }}</h3>
          <mq-list :items='listItems(team)' :checkmark='false' :color='secondaryColor'></mq-list>
        </template>
        <template slot='button'>
          <mq-button as='link' color='#FFF' size='tiny' :href='signUpUrl(team)'>
            {{ $t('buttons.select') }}
          </mq-button>
        </template>
        <template slot='bottom' v-if='planDescription(team)'>
          <p class='pre' v-html='planDescription(team)'></p>
        </template>
      </mq-extended-panel>
    </template>
  </step-wrapper>
</template>

<style lang='scss'>
  @import '~@moqomps/style';

  #subscription_selection{
    @include breakpoint(small down) {
      padding-bottom: rem-calc(50);
    }

    li {
      color: inherit;
      margin-bottom: 0;
    }
  }
</style>
