<script>
  import MqButton from "../../MOQOmponents/components/MqButton";

  export default {
    components: {MqButton},

    computed: {
      magicLoginUrl () {
        return this.$currentUser?.magic_login_url
      },
      
      pageContent () {
        const newOrAddTeam = this.$signUpForm.data?.new_registration ?
          'success.content.new_registration.' :
          'success.content.add_team.'

        let content = this.$t(newOrAddTeam + 'confirmation')

        if (this.$signUpForm.data?.skip_payment_method) {
          content = `${content} ${this.$t(newOrAddTeam + 'skiped_payment_method')}`
        } else {
          content = `${content} ${this.$t(newOrAddTeam + 'completed')}`
        }
        
        return content
      }
    }
  }
</script>


<template>
  <div id='success'>
    <div class='row'>
      <div class='column small-offset-1 small-22'>
        <div class='row column large-gutter-top'>
            <h2 class='medium-gutter-top'>{{ $t('success.page_title') }}</h2>
            
            <p class='medium-gutter-bottom text-center'>
              <svg-icon name="thumb_up" size="200px" :fill="primaryColor"></svg-icon>
            </p>

            <p>
              {{ pageContent }}
            </p>
  
            <mq-input-row as='submit'>
              <p class='text-center'>
                <mq-button as='link' size='small' :color='primaryColor' :href='magicLoginUrl'>{{ $t('buttons.finish_in_app') }}</mq-button>
              </p>
            </mq-input-row>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang='scss'>
  @import '~@moqomps/style';

  #success{

    .success-image {
      max-width: rem-calc(75);
    }

  }
</style>
