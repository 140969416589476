export const FIELDS = {
  TERMS_CONDITIONS: [
    'terms_acceptance',
    'minimum_age_requirement_acceptance',
    'eula_acceptance'
  ],
  TEAM_SELECTION: [
    'team_id',
  ],
  EXTERNAL_CUSTOMER_ID: [
    'external_customer_id'
  ],
  ACCOUNT: [
    'first_name',
    'last_name',
    'license_number',
    'email',
    'terms_acceptance',
    'password',
    'minimum_age_requirement_acceptance',
    'eula_acceptance'
  ],
  PERSONAL_DETAILS: [
    'street',
    'house_number',
    'address_addition',
    'zipcode',
    'city',
    'country',
    'birthday',
    'mobile_number',
    'tax_id',
    'region'
  ],
  PAYMENT_DETAILS: [
    'payin_source_token'
  ]
}
