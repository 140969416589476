export default {
  created () {
    this.$set(this.$signUpForm.record, 'minimum_age_requirement_acceptance', !(this.$signUpSettings?.minimum_age_requirement))
  },

  computed: {
    minimumAgeRequirement () {
      return this.$signUpSettings?.minimum_age_requirement
    },

    minimumAgeRequirementAccepted () {
      return !this.minimumAgeRequirement || this.$signUpForm.record.minimum_age_requirement_acceptance
    }
  },

  methods: {
    validateMinimumAgeRequirement () {
      if(this.minimumAgeRequirement && !this.$signUpForm.record.minimum_age_requirement_acceptance) {
        this.$set(this.$signUpForm.validation, 'minimum_age_requirement_acceptance', [this.$t('form.error.minimum_age_requirement_acceptance', {age: this.minimumAgeRequirement})])
      } else {
        this.$delete(this.$signUpForm.validation, 'minimum_age_requirement_acceptance')
      }
    },
  }
}
